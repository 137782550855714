/* COLORS */
$color_white: #fff;
$color_gray_light: #9a9da1;
$color_gray: #3f444b;
$color_gray_medium: #29313d;
$color_gray_dark: #1f252e;
$color_yellow_full: #f5b928;
$color_yellow_dull: #fbe2a5;
$color_red: #cd3838;
$color_black: #000;
