@import "src/styles/variables";

.button {
  height: 42px;
  background-color: $color_yellow_full;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  color: $color_gray_dark;
  border: none;

  &:disabled {
    background: $color_gray;
  }

  &.outline {
    border: 1px solid $color_yellow_full;
    background: transparent;
    color: $color_yellow_full;
  }
}
