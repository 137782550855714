@import "src/styles/variables";

.index {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 1;
}

.button {
  margin-bottom: 16px;
}

.link {
  text-decoration: none;
  color: $color_yellow_full;
  margin-top: 10px;
  align-self: center;
}

.logo {
  margin-top: 5rem;
  margin-bottom: 7rem;
}
