@import "src/styles/variables";

.layout {
  background: $color_gray_dark;
  height: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;

  .scrollable {
    flex: 1;
    width: calc(100% - 32px);
    padding: 16px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 40px;
    max-width: 430px;
  }
}

.backButton {
  align-self: flex-start;
  background: none;
  outline: none;
  border: none;
  padding: 0 20px 20px 0;
}

.authHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logout {
    text-transform: uppercase;
    color: $color_yellow_full;
    cursor: pointer;
  }
}

.actions {
  height: 42px;
  padding: 16px;
}
